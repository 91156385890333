import CountUp from 'react-countup';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/app/store/store';
import { selectTheme } from 'src/app/store/slices/user/selectors';
import { EThemeMode } from 'src/app/store/slices/user/types';
import useAlert from 'src/shared/libs/hooks/use-alert';
import Button from 'src/shared/ui/button/button/button';
import { selectHiddenBalance } from 'src/pages/settings/model/selectors';
import { HIDDEN_BALANCES } from 'src/shared/constants/constants';
import { fetchReferralsStatistics } from '../../model/thunk';
import { selectReferralsStatistics } from '../../model/selectors';
import styles from './referrals-statistics.module.scss';

const ReferralsStatistics = () => {
  const { setAlertMessage } = useAlert();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const theme = useSelector(selectTheme);

  const isDark = theme === EThemeMode.DARK;
  const balanceVisible = useSelector(selectHiddenBalance);
  const statistics = useSelector(selectReferralsStatistics);

  const handleWithddrawaBonus = () => {
    setAlertMessage('under_development', 'warning');
  };

  useEffect(() => {
    dispatch(fetchReferralsStatistics());
  }, []);

  return (
    <div className={styles.statistics}>
      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('balance_bonuses')}</h3>
        <p className={styles.value}>
          {!balanceVisible ? <CountUp decimal="." end={Number(statistics?.balance) || 0} /> : HIDDEN_BALANCES}
        </p>
        {/* <p className={styles.valueToday}>
          {!balanceVisible ? <CountUp decimal="." prefix={statistics && statistics.profit_today > 0 ? '+' : ''} end={Number(statistics?.profit_today) || 0} /> : HIDDEN_BALANCES}
          {t('today')}
        </p> */}
        <Button onClick={handleWithddrawaBonus} className={styles.btnWithdraw} background={isDark ? 'white' : 'black'}>{t('withdraw_funds')}</Button>
      </div>

      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('widget_register')}</h3>
        <p className={styles.value}>
          {!balanceVisible ? <CountUp decimal="." end={Number(statistics?.registrations || 0)} /> : HIDDEN_BALANCES}
        </p>
        <p className={styles.valueToday}>
          {!balanceVisible ? <CountUp decimal="." prefix={statistics && statistics.registrations_today > 0 ? '+' : ''} end={Number(statistics?.registrations_today) || 0} /> : HIDDEN_BALANCES}
          {t('today')}
        </p>
      </div>

      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('widget_traders')}</h3>
        <p className={styles.value}>
          {!balanceVisible ? <CountUp decimal="." end={Number(statistics?.traders || 0)} /> : HIDDEN_BALANCES}
        </p>
        <p className={styles.valueToday}>
          {!balanceVisible ? <CountUp decimal="." prefix={statistics && statistics.traders_today > 0 ? '+' : ''} end={Number(statistics?.traders_today) || 0} /> : HIDDEN_BALANCES}
          {t('today')}
        </p>
      </div>

      <div className={styles.widgetContainer}>
        <h3 className={styles.title}>{t('widget_profit_usdt')}</h3>
        <p className={styles.value}>
          {!balanceVisible ? (
            <CountUp
              decimal="."
              end={Number(statistics?.profit || 0)}
            />
          ) : HIDDEN_BALANCES}
        </p>
        <p className={styles.valueToday}>
          {!balanceVisible ? <CountUp decimal="." prefix={statistics && statistics.profit_today > 0 ? '+' : ''} end={Number(statistics?.profit_today) || 0} /> : HIDDEN_BALANCES}
          {t('today')}
        </p>
      </div>

    </div>
  );
};

export default memo(ReferralsStatistics);
