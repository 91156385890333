import { AxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { $api } from 'src/shared/api/axios.instance';
import { ROUTE_PATH } from 'src/shared/constants/route.path';
import {
  EChartsType, EWidgetData, InheritedFilters, InheritedFiltersParams,
  ResponseUpdateWidgetsChart,
  WidgetChart,
} from './types';
import { removeWidget, updateWidget } from './slice';

export type AddWidgetParams = {
  data: EWidgetData[]
  chart_type: EChartsType
  is_inherited: boolean
  x: number
  y: number
  width: number
  height: number
} & Partial<InheritedFilters>;

export type UpdateParamsWithWidgetChart = Partial<AddWidgetParams> & {
  widget_id: number;
}[];

export const fetchInheritedFiltersDashboard = createAsyncThunk(
  'dashboard/fetchInheritedFiltersDashboard',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<InheritedFilters>(ROUTE_PATH.tradingJournal.inheritedFilters);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchChangeInheritedFilters = createAsyncThunk(
  'dashboard/fetchChangeInheritedFilters',
  async (params: InheritedFiltersParams, { rejectWithValue }) => {
    try {
      const response = await $api.put(ROUTE_PATH.tradingJournal.inheritedFilters, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchAddWidget = createAsyncThunk(
  'dashboard/fetchAddWidget',
  async (params: AddWidgetParams, { rejectWithValue }) => {
    try {
      const response = await $api.post(ROUTE_PATH.tradingJournal.widgets, params);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchWidgetCharts = createAsyncThunk(
  'dashboard/fetchWidgetCharts',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get<WidgetChart[]>(ROUTE_PATH.tradingJournal.widgets);

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchDeleteWidgetChart = createAsyncThunk(
  'dashboard/fetchDeleteWidgetChart',
  async (widgetId: number, { dispatch, rejectWithValue }) => {
    try {
      dispatch(removeWidget(widgetId));

      const response = await $api.delete<{ widget_id: number }>(ROUTE_PATH.tradingJournal.widgets, {
        data: { widget_id: widgetId },
      });

      const { data } = response;

      if (data === undefined) throw response;

      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);

export const fetchUpdateWidgetChart = createAsyncThunk(
  'dashboard/fetchUpdateWidgetChart',
  async (params: UpdateParamsWithWidgetChart, { dispatch, rejectWithValue }) => {
    try {
      const response = await $api.put<ResponseUpdateWidgetsChart>(ROUTE_PATH.tradingJournal.widgets, {
        widgets: params,
      });

      const { data } = response;

      if (data === undefined) throw response;
      dispatch(updateWidget(data));
      return data;
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) return rejectWithValue(error.response.data.detail);
      return rejectWithValue(error instanceof Error ? error.message : String(error));
    }
  },
);
