/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { useId } from 'react';
import { join } from 'src/shared/libs/helpers/helper.lib';
import { ReactComponent as InfoSvg } from 'src/shared/assets/images/auth/info.svg';

import './toogler.scss';

interface ITooglerProps {
  isActive: boolean;
  labelContent?: string;
  comment?: string;
  isBlocked?: boolean;
  onChange?: (state: boolean) => void
  isActiveDisabled?: boolean
  isLoading?: boolean
}

const Toogler = ({
  isActive,
  labelContent,
  comment,
  isBlocked,
  onChange,
  isActiveDisabled,
  isLoading,
}: ITooglerProps) => {
  const uniqueId = `toogler-${useId()}`;

  return (
    <div className={join('toogler', isActiveDisabled && 'disabled-toggle', isLoading && 'loading-toogle')}>
      <label
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            if (!isBlocked && onChange) onChange(!isActive);
          }
        }}
        tabIndex={0}
        htmlFor={uniqueId}
        className={join('toogler-button', isActive && 'active-toogle', isActiveDisabled && 'disabled-toogle', isBlocked && 'blocked-toogle')}
      >
        <input
          id={uniqueId}
          type="checkbox"
          checked={isActive}
          onChange={() => !isBlocked && onChange && onChange(!isActive)}
          disabled={isActiveDisabled}
        />
        <span />
      </label>

      <label
        className={join('toogler-content', isBlocked && 'blocked-toogle-label')}
        htmlFor={uniqueId}
      >
        {labelContent && <p>{labelContent}</p>}
      </label>

      { comment && <span className="comment"><InfoSvg /> {comment}</span> }
    </div>
  );
};

export default Toogler;
