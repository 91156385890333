import { EStatus } from 'src/shared/types/global-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDashboardSlice, ResponseUpdateWidgetsChart, WidgetChart } from './types';
import {
  fetchAddWidget, fetchChangeInheritedFilters, fetchInheritedFiltersDashboard, fetchWidgetCharts,
} from './thunks';

const initialState: IDashboardSlice = {
  widgets: null,
  inheritedFilters: null,
  statusInheritFilters: EStatus.success,
  statusAddWidget: EStatus.success,
  statusGetWidgets: EStatus.loading,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    removeWidget: (state, action: PayloadAction<number>) => {
      if (state.widgets) {
        state.widgets = state.widgets.filter((widget) => widget.id !== action.payload);
      }
    },
    updateWidget: (state, action: PayloadAction<ResponseUpdateWidgetsChart>) => {
      if (!state.widgets) return;

      state.widgets = action.payload.widgets;
    },
    clearDashboardSlice: (state) => {
      state.inheritedFilters = null;
      state.widgets = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInheritedFiltersDashboard.pending, (state) => {
        state.statusInheritFilters = EStatus.loading;
        state.inheritedFilters = null;
      })
      .addCase(fetchInheritedFiltersDashboard.fulfilled, (state, action) => {
        state.inheritedFilters = action.payload;
        state.statusInheritFilters = EStatus.success;
      })
      .addCase(fetchInheritedFiltersDashboard.rejected, (state) => {
        state.statusInheritFilters = EStatus.rejected;
        state.inheritedFilters = null;
      })
      .addCase(fetchChangeInheritedFilters.pending, (state) => {
        state.statusInheritFilters = EStatus.loading;
      })
      .addCase(fetchChangeInheritedFilters.fulfilled, (state, action) => {
        state.statusInheritFilters = EStatus.success;
        state.inheritedFilters = action.payload;
      })
      .addCase(fetchChangeInheritedFilters.rejected, (state) => {
        state.statusInheritFilters = EStatus.rejected;
      })
      .addCase(fetchAddWidget.pending, (state) => {
        state.statusAddWidget = EStatus.loading;
      })
      .addCase(fetchAddWidget.fulfilled, (state, action) => {
        state.statusAddWidget = EStatus.success;

        if (state.widgets) {
          state.widgets.push(action.payload);
        } else {
          state.widgets = [action.payload];
        }
      })
      .addCase(fetchAddWidget.rejected, (state) => {
        state.statusAddWidget = EStatus.rejected;
      })
      .addCase(fetchWidgetCharts.pending, (state) => {
        state.statusGetWidgets = EStatus.loading;
        state.widgets = null;
      })
      .addCase(fetchWidgetCharts.fulfilled, (state, action) => {
        state.statusGetWidgets = EStatus.success;
        state.widgets = action.payload;
      })
      .addCase(fetchWidgetCharts.rejected, (state) => {
        state.statusGetWidgets = EStatus.rejected;
        state.widgets = null;
      });
  },
});

export const { clearDashboardSlice, removeWidget, updateWidget } = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;
