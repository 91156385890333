import classNames from 'classnames';
import HamsterIconSvg from 'src/shared/assets/images/trading/wait-positions.svg';
import HamsterErrorIconSvg from 'src/shared/assets/images/hamster-error.svg';
import HamsterErrorHoverIconSvg from 'src/shared/assets/images/hamster-error-hover.svg';
import HamsterAddIconSvg from 'src/shared/assets/images/hamster-addd.svg';
import styles from './empty.module.scss';

interface IEmptyProps {
  children: React.ReactNode;
  error?: boolean;
  horizontal?: boolean;
  className?: string;
  maxWidthText?: string;
  isAdd?: boolean; // Логика для isAdd
}

const Empty = ({
  children, error, horizontal, className, maxWidthText, isAdd,
}: IEmptyProps) => {
  const contentClassNames = classNames(
    className,
    styles.empty,
    {
      [styles.error]: error,
      [styles.horizontal]: horizontal,
      [styles.isAdd]: isAdd,
    },
  );

  return (
    <div className={contentClassNames}>
      <div className={styles.text} style={{ maxWidth: maxWidthText }}>
        {children}
      </div>

      {error ? (
        <div className={styles.hamsterWrapper}>
          <div className={styles.see}>
            <img src={HamsterErrorIconSvg} alt="hamster" />
          </div>
          <div className={styles.notSee}>
            <img src={HamsterErrorHoverIconSvg} alt="hamster" />
          </div>
        </div>
      ) : isAdd ? (
        <div className={styles.addContent}>
          <img src={HamsterAddIconSvg} alt="hamster" />
        </div>
      ) : (
        <img src={HamsterIconSvg} alt="hamster" />
      )}
    </div>
  );
};

export default Empty;
